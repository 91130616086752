<template>
  <div :id="`CALENDAR_EXCEPTION_${editEvent.uuId}`" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="md" :title="labelTitle" content-class="shadow" no-close-on-backdrop
      @hidden="modalCancel">
      <b-alert variant="danger" dismissible v-model="errorShow" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }} 
      </b-alert>
    
    <b-row>
      <b-col cols="12" md="6" class="pr-md-2">
      <b-form-group :label="$t('calendar.field.name')" label-for="name">
        <b-input-group>
          <b-form-input id="name" type="text"
            :data-vv-as="$t('calendar.field.name')"
            data-vv-name="editEvent.name"
            data-vv-delay="500"
            v-model="editEvent.name"
            :readonly="readOnly"
            >
          </b-form-input>
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('editEvent.name') }}
        </b-form-invalid-feedback>
      </b-form-group>
      </b-col>
      <b-col cols="12" md="6" class="pl-md-2">
        <b-form-group :label="$t('field.identifier')" label-for="identifier">
            <b-input-group>
              <b-form-input id="identifier" type="text"
                :data-vv-as="$t('field.identifier')"
                data-vv-name="calendar.field.identifier"
                :maxlength="maxIdentifierLength"
                v-model="editEvent.identifier" 
                :disabled="readOnly"
                trim>
              </b-form-input>
            </b-input-group>
          </b-form-group>
      </b-col>
    </b-row>
      <b-row>
        <b-col cols="12" md="6" class="pr-md-2">
          <label for="exception-startDate">{{ $t('calendar.field.startDate') }}</label>
          <b-form-datepicker id="exception-startDate" v-model="editEvent.startDate" :class="{ 'mb-3': !showStartDateError }"
            :max="editEvent.endDate"
            today-button
            reset-button
            close-button
            hide-header
            :readonly="readOnly"
            :label-today-button="$t('date.today')"
            :label-reset-button="$t('date.reset')"
            :label-close-button="$t('date.close')"
            today-button-variant="primary"
            reset-button-variant="danger" 
            close-button-variant="secondary"
            :state="fieldValidateUtil.stateValidate(readOnly, veeFields, errors, 'editEvent.startDate')"
            :data-vv-as="$t('calendar.field.startDate')"
            data-vv-name="editEvent.startDate"
            data-vv-delay="500"
            v-validate="{ required: true }"
          >
            <template v-slot:button-content="{ }">
              <font-awesome-icon :icon="['far', 'calendar-days']" />
            </template>
          </b-form-datepicker>
          <b-form-invalid-feedback class="alert-danger form-field-alert mb-3" :class="{ 'd-block': showStartDateError }">
            <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('editEvent.startDate') }}
          </b-form-invalid-feedback>
        </b-col>
        <b-col cols="12" md="6" class="pl-md-2">
          <label for="exception-endDate">{{ $t('calendar.field.endDate') }}</label>
          <b-form-datepicker id="exception-endDate" v-model="editEvent.endDate"  :class="{ 'mb-3': !showEndDateError }"
            :min="editEvent.startDate"
            today-button
            reset-button
            close-button
            hide-header
            :readonly="readOnly"
            :label-today-button="$t('date.today')"
            :label-reset-button="$t('date.reset')"
            :label-close-button="$t('date.close')"
            today-button-variant="primary"
            reset-button-variant="danger" 
            close-button-variant="secondary"
            :state="fieldValidateUtil.stateValidate(readOnly, veeFields, errors, 'editEvent.endDate')"
            :data-vv-as="$t('calendar.field.endDate')"
            data-vv-name="editEvent.endDate"
            data-vv-delay="500"
            v-validate="{ required: true }"
          >
            <template v-slot:button-content="{ }">
              <font-awesome-icon :icon="['far', 'calendar-days']" />
            </template>
          </b-form-datepicker>
          <b-form-invalid-feedback class="alert-danger form-field-alert mb-3" :class="{ 'd-block': showEndDateError }">
            <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('editEvent.endDate') }}
          </b-form-invalid-feedback>
        </b-col>  
      </b-row>
      
      <b-form-group label="">
        <b-form-radio-group id="working" v-model="editEvent.isWorking" name="isWorking" @change="workingChange">
          <b-form-radio :disabled="readOnly" :value="false">{{ $t('calendar.non_working') }}</b-form-radio>
          <b-form-radio :disabled="readOnly" :value="true">{{ $t('calendar.working') }}</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      
      <b-row class="calendar-exception-time" v-if="isWorking">
        <b-col cols="12" md="6" class="pr-md-2">
          <label for="startHour">{{ $t('calendar.field.from') }}</label>
          <Timepicker v-model="editEvent.startHour"
            @context="onContext($event, 'startHour')"
            :readonly="readOnly">
          </Timepicker>
          <b-form-invalid-feedback class="alert-danger form-field-alert mb-3" :class="{ 'd-block': showStartHourError }">
            <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('editEvent.startHour') }}
          </b-form-invalid-feedback>
        </b-col>

        <b-col cols="12" md="6" class="pl-md-2">
          <label for="endHour">{{ $t('calendar.field.to') }}</label>
          <Timepicker v-model="editEvent.endHour" 
            @context="onContext($event, 'startHour')"
            :readonly="readOnly">
          </Timepicker>
          <b-form-invalid-feedback class="alert-danger form-field-alert mb-3" :class="{ 'd-block': showEndHourError }">
            <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('editEvent.endHour') }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button v-if="!readOnly" size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button v-if="!readOnly" size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        <b-button v-else size="sm" variant="secondary" @click="cancel()">{{ $t('button.close') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { timeToMs } from '@/helpers';

export default {
  name: 'CalendarExceptionModal',
  components: {
    Timepicker: () => import('@/components/Calendar/Timepicker.vue')
  },
  props: {
    event:        { type: Object, default: null },
    title:        { type: String, default: null },
    show:         { type: Boolean, required: true },
    baseStartHour:{ type: String, default: null },
    baseEndHour:  { type: String, default: null },
    readOnly:     { type: Boolean, default: false }
  },
  data() {
    return {
      modelInfo: null,
      modalShow: false,
      alertMsg: null,
      editEvent: {
        uuId: null,
        name: null,
        startDate: null,
        endDate: null,
        isWorking: false,
        startHour: null,
        endHour: null,
        calendarName: null,
        identifier: null,
      }
    }
  },
  created() {
    this.fieldValidateUtil = fieldValidateUtil;
    this.validate = true;
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
  },
  watch: {
    show(newValue) {
      if (newValue != this.modalShow) {
        this.$validator.resume();
        this.modalShow = newValue;
        this.alertMsg = null;
        this.errors.clear();
        // clone the event data for editing
        const keys = Object.keys(this.editEvent);
        keys.forEach(i => {
          this.editEvent[i] = this.event[i];
        });
      }
    }
  },
  computed: {
    errorShow() {
      return this.alertMsg != null;
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'editEvent.name');
    },
    showStartDateError() {
      return fieldValidateUtil.hasError(this.errors, 'editEvent.startDate');
    },
    showEndDateError() {
      return fieldValidateUtil.hasError(this.errors, 'editEvent.endDate');
    },
    showStartHourError() {
      return this.editEvent.isWorking? fieldValidateUtil.hasError(this.errors, 'editEvent.startHour'): false;
    },
    showEndHourError() {
      return this.editEvent.isWorking? fieldValidateUtil.hasError(this.errors, 'editEvent.endHour'): false;
    },
    labelTitle() {
      return this.title? this.title : this.$t('calendar.exception_title_edit');
    },
    ignoreHourValidate() {
      return this.readOnly || !this.isWorking;
    },
    isWorking() {
      return this.editEvent && this.editEvent.isWorking;
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "identifier";
      });
      return values.length !== 0 ? values[0].max : 200;
    }
  },
  methods: {
    getModelInfo() {
      const self = this;
      this.$store.dispatch('data/info', {type: "api", object: "CALENDAR"}).then(value => {
        self.modelInfo = value.CALENDAR.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    workingChange(value) {
      if (value) {
        this.editEvent.startHour = this.baseStartHour;
        this.editEvent.endHour = this.baseEndHour;
      }
      else {
        this.editEvent.startHour = null;
        this.editEvent.endHour = null;
      }
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    ok() {
      this.errors.clear();
      this.alertMsg = null;

      // if (this.editEvent.isWorking && this.editEvent.endHour && (this.editEvent.endHour == '00:00' || this.editEvent.endHour == '00:00:00')) {
      //   this.editEvent.endHour = '24:00';
      // }

      this.$validator.validate().then(valid => {
        if(this.editEvent.name == null || this.editEvent.name.trim().length < 1) {
          this.errors.add({
            field: 'editEvent.name',
            msg: this.$t('validation.messages.required', [this.$t('calendar.field.name')])
          });
        }
        if(this.editEvent.isWorking && (!this.editEvent.startHour || !this.editEvent.endHour)) {
          if(!this.editEvent.startHour) {
            this.errors.add({
              field: 'editEvent.startHour',
              msg: this.$t('validation.messages.required', [this.$t('calendar.field.startHour')])
            });
          }
          if(!this.editEvent.endHour) {
            this.errors.add({
              field: 'editEvent.endHour',
              msg: this.$t('validation.messages.required', [this.$t('calendar.field.endHour')])
            });
          }
        }
        if (this.editEvent.isWorking && this.editEvent.startHour && this.editEvent.endHour) {
          const startMs = timeToMs(this.editEvent.startHour);
          const endMs = (this.editEvent.endHour == '00:00' || this.editEvent.endHour == '00:00:00')? timeToMs('24:00') : timeToMs(this.editEvent.endHour);
          
          if (endMs < startMs) {
            this.errors.add({
              field: 'editEvent.endHour',
              msg: this.$t('calendar.error.endhour_cant_be_less_than_starthour')
            });
          }
        }
        if (valid && this.errors.count() < 1) {
          const cloned = cloneDeep(this.editEvent);
          let msg = this.$t('calendar.grid.exception_update');
          if(cloned.uuId.startsWith('EXCEPTION_NEW')) {
            cloned.uuId = cloned.uuId.replace('_NEW', '');
            msg = this.$t('calendar.grid.exception_add');
          }
          cloned.type = this.event.type;
          this.$validator.reset();
          this.$emit('success', { msg, event: cloned });
          this.$emit('update:show', false);
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
    },
    modalCancel() {
      this.$validator.pause();
      this.$emit('update:show', false)
    },
    scrollToTop() {
      document.querySelector(`#CALENDAR_EXCEPTION_${this.editEvent.uuId}`).scrollIntoView();
    },
    onContext(ctx, key) {
      if(ctx.hours != null && ctx.minutes == null){
        this.editEvent[key] = `${ctx.hours}:00:00`;
        //Call forceUpdate twice to make sure the minute value shows up in GUI.
        this.$forceUpdate();
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      }
    }
  }
}
</script>

<style>
  .flex-container {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
  }
      
  .space-evenly { 
    justify-content: space-evenly; 
  }
  
  .date-field {
    width: 40%;
    display: inline-block;
  }
  
</style>

<style lang="scss">
.calendar-exception-time .b-time-minutes .btn {
  display: none;
}
.calendar-exception-time .b-time-minutes output.border-bottom.border-top {
  border-bottom: none !important;
  border-top: none !important;
}
</style>